.copy-icon {
  transition: opacity 150ms ease-in-out;
}

.absolute-value-display {
  opacity: 0;
}

.data-container:hover {
  .copy-icon {
    opacity: 1;
  }

  .absolute-value-display {
    opacity: 1;
  }
}
