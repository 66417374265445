.sidebar-content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;

  .footer-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .module-form-container {
    display: flex;
    flex-direction: column;
    padding: 0;
    padding-bottom: 150px;
    overflow-y: scroll;

    .form-section {
      margin-bottom: 10px;
      .form-section-title {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 10px;
      }
    }

    .form-item-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;

      label {
        font-size: 14px;
        opacity: 0.7;
        font-weight: 500;
      }

      .custom-label {
        height: 18px;
        width: 18px;
        margin-bottom: 3px;
      }

      span,
      input {
        font-weight: 500;
        font-size: 16px !important;
      }

      .create-city-button {
        font-size: 14px;
        font-style: italic;
        font-weight: 500;
        margin: 10px 0px;
        color: #005a78cc;
        cursor: pointer;

        &:hover {
          color: #005a78;
        }
      }

      .checkbox-container {
        margin-top: 5px;
        display: inline-flex;
        flex-wrap: wrap;
        gap: 20px;
        .checkbox-input {
          display: inline-flex;
          flex-wrap: wrap;
          gap: 10px;
        }
      }
    }
    .form-information {
      margin-top: 15px;
      font-size: 16px;
      font-style: italic;
    }
  }
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  font-size: 16px !important;
}

input[type="file"] {
  display: none;
}

.autocomplete-item-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.autocomplete-new-item {
  font-style: italic;
}
