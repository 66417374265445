body {
  margin: 0;
  /*     font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif; */
  font-family: "Montserrat", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}
html {
  overflow: hidden;
  font-size: 16px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*{
  scrollbar-width: thin;
}

*::-webkit-scrollbar {
  width: 7px;
  
}

:root {
  --red: #e13834;
  --purple: #ba3766;
  --light-green: #61b345;
  --green: #037868;
  --orange: #f6a414;
  --blue: #005a78;
  --white: #fff;
  --white-bg: #edf0f4;
  --grey: #707070;
  --darkGrey: #3b3a3a;
  --dark: #212121;

  --navbarWidth : 540px;
}

.hidden-scrollbar::-webkit-scrollbar {
  width: Opx;
  height: 0px;
  background: transparent;
  /* display: 'none', */
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.row-between {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.row-gap {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.row-gap-xs {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 4px;
  align-items: center;
}

.autocomplete-input {
  width: 100%;
}

.p-autocomplete-multiple-container {
  width: 100%;
  margin-bottom: 0;
  margin-right: 10px;
}

.p-column-title {
  font-weight: 500;
}

.margin-y {
  margin: 10px 0;
}

.green-background {
  background-color: #61b34520 !important;
}

.light-green-background {
  background-color: #60b34510 !important;
}

.red-background {
  background-color: #f6a41420 !important;
}

.bold-balance {
  font-weight: 700;
}

.pill-container {
  width: fit-content;
  text-align: center;
  font-size: 16px;
  border-radius: 5px;
  color: white;
  font-weight: 600;
  padding: 0 0.5vw;
  margin-right: 0.5vw;
  height: 22px;
  line-height: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    height: 12px;
    margin-left: 8px;
  }
}

/* TOP NAVBAR STYLING */

.nav-text-container {
  height: 9vh;
  width: auto;
  padding-left: 1vw;
  padding-right: 1vw;
  margin-left: 1vw;
  margin-right: 1vw;
  padding-bottom: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-text {
  font-size: 1vw;
  font-weight: 400;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nav-text-button-inactive {
  border-top: 2px solid transparent;
  opacity: 0.7;
}

.nav-text-button-inactive:hover {
  cursor: pointer;
  border-top: 2px solid white;
  opacity: 1;
}

.nav-text-button-active {
  background: linear-gradient(#ffffff40, #ffffff0d);
  cursor: pointer;
  border-top: 2px solid white;
  opacity: 1;
}

.logout-button {
  opacity: 0.7;
}

.logout-button:hover {
  opacity: 1;
}

.hover-login:hover {
  opacity: 100%;
  cursor: pointer;
}

.hover-login {
  opacity: 80%;
  font-size: 2.5vh;
}

/* BREADCRUMBS STYLING */
.breadcrumb-active {
  font-size: 2.7vh;
  font-weight: 800;
}

.breadcrumb-inactive {
  font-size: 2.7vh;
  font-weight: 800;
  opacity: 0.7;
  cursor: pointer;
}

.breadcrumb-inactive:hover {
  opacity: 1;
  cursor: pointer;
}

/* APP COMPONENTS */
.app-button {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
}

.modal {
  /* position:absolute; */
  top: 11vh;
  left: 6vw;
  border-radius: 5px;
}

.modal-background {
  position: absolute;
  height: 100%;
  width: 105%;
  background-color: #000000d1;
}

.copy-icon:hover {
  cursor: pointer;
}

.inactive-dropdown {
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
}

/* FORM CLASS CSS */

.grid-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* AUTOCOMPLETE */
.autocomplete-result-item {
  cursor: pointer;
  height: 4vh;
  width: 100%;
  font-size: 1.7vh;
  padding: 0 0.6vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #000000cc;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.autocomplete-result-item:hover {
  font-weight: 600;
  color: #000000;
}

.header-input-form {
  height: auto;
  width: 90%;
  font-size: 1.6vh;
  font-weight: 500;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.input-form::placeholder {
  color: #707070;
  font-style: italic;
  font-size: 1.5vh;
  font-weight: 300;
}

.input-form {
  padding-left: 0.6vw;
  padding-right: 0.6vw;
  margin-top: 1vh;
  height: 4vh;
  width: 90%;
  background-color: white;
  border-radius: 0.35vh;
  border: 0;
  color: black;
  font-size: 1.7vh;
  font-weight: 500;
}

.input-form-checkbox {
  padding-left: 0.6vw;
  padding-right: 0.6vw;
  margin-top: 1vh;
  height: 4vh;
  width: 4vh;
  background-color: white;
  border-radius: 0.35vh;
}

.input-form-half::placeholder {
  color: #707070;
  font-style: italic;
  font-size: 1.5vh;
  font-weight: 300;
}

.input-form-half {
  padding-left: 0.6vw;
  padding-right: 0.6vw;
  margin-top: 1vh;
  height: 4vh;
  width: 40%;
  background-color: white;
  border-radius: 0.35vh;
  border: 0;
  color: black;
  font-size: 1.7vh;
  font-weight: 500;
}

.input-form-textarea {
  padding-left: 0.6vw;
  padding-right: 0.6vw;
  padding-top: 0.6vw;
  padding-bottom: 0.6vw;
  margin-top: 1vh;
  height: 14vh;
  width: 90%;
  background-color: white;
  border-radius: 0.35vh;
  border: 0;
  color: black;
  font-size: 1.7vh;
  font-weight: 500;
}

.input-form-big::placeholder {
  color: #707070;
  font-style: italic;
  font-size: 1.5vh;
  font-weight: 300;
}

.input-form-big {
  padding-left: 0.6vw;
  padding-right: 0.6vw;
  padding-top: 0.4vw;
  margin-top: 1vh;
  height: 12vh;
  width: 90%;
  background-color: white;
  border-radius: 0.35vh;
  border: 0;
  color: black;
  font-size: 1.7vh;
  font-weight: 500;
}

.input-form-transparent::placeholder {
  color: #edf0f4;
  font-style: italic;
  font-size: 1.5vh;
  font-weight: 300;
}

.input-form-transparent {
  padding-left: 0.6vw;
  padding-right: 0.6vw;
  margin-top: 1vh;
  height: 4vh;
  width: 90%;
  background-color: #005a78;
  border-radius: 5px;
  border: 1px solid #edf0f4;
  color: #edf0f4;
  font-size: 1.7vh;
  font-weight: 600;
}

.input-form-mandatory-pill {
  background-color: #e13834;
  border-radius: 15px;
  padding: 2px 7px;
  color: white;
  font-size: 1.2vh;
  margin-left: 10px;
}

.photo-component-container {
  position: relative;
  overflow: hidden;
  height: 15vh;
  width: 14vw;
  border-radius: 10px;
  margin-right: 1vw;
}

.underlined-on-hover:hover {
  text-decoration: underline;
}

.underlined-on-hover {
  text-decoration: none;
}

/* TOASTER */

.Toastify__toast-container--bottom-right {
  margin-right: 2vw;
}

.Toastify__toast {
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  font-size: 0.8vw;
  border-radius: 5px;
}

.Toastify__toast--success {
  background-color: #61b345 !important;
}

.Toastify__toast--error {
  background-color: #e13834 !important;
}

.Toastify__toast--warning {
  background-color: #f6a414 !important;
}
