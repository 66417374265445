// Configuration for the font-face of the theme, defaults to the system font so left as blank
@font-face {
  font-family: 'Montserrat';
  font-weight: 100;
  font-display: swap;
  font-style: normal;
  font-named-instance: 'Thin';
  src: url('./fonts/Montserrat-Thin.woff2') format('woff2');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 100;
  font-display: swap;
  font-style: italic;
  font-named-instance: 'Thin Italic';
  src: url('./fonts/Montserrat-ThinItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 200;
  font-display: swap;
  font-style: normal;
  font-named-instance: 'ExtraLight';
  src: url('./fonts/Montserrat-ExtraLight.woff2') format('woff2');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 200;
  font-display: swap;
  font-style: italic;
  font-named-instance: 'ExtraLight Italic';
  src: url('./fonts/Montserrat-ExtraLightItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 300;
  font-display: swap;
  font-style: normal;
  font-named-instance: 'Light';
  src: url('./fonts/Montserrat-Light.woff2') format('woff2');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 300;
  font-display: swap;
  font-style: italic;
  font-named-instance: 'Light Italic';
  src: url('./fonts/Montserrat-LightItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  font-display: swap;
  font-style: normal;
  font-named-instance: 'Regular';
  src: url('./fonts/Montserrat-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  font-display: swap;
  font-style: italic;
  font-named-instance: 'Regular Italic';
  src: url('./fonts/Montserrat-Italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  font-display: swap;
  font-style: normal;
  font-named-instance: 'Medium';
  src: url('./fonts/Montserrat-Medium.woff2') format('woff2');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  font-display: swap;
  font-style: italic;
  font-named-instance: 'Medium Italic';
  src: url('./fonts/Montserrat-MediumItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  font-display: swap;
  font-style: normal;
  font-named-instance: 'SemiBold';
  src: url('./fonts/Montserrat-SemiBold.woff2') format('woff2');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  font-display: swap;
  font-style: italic;
  font-named-instance: 'SemiBold Italic';
  src: url('./fonts/Montserrat-SemiBoldItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  font-display: swap;
  font-style: normal;
  font-named-instance: 'Bold';
  src: url('./fonts/Montserrat-Bold.woff2') format('woff2');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  font-display: swap;
  font-style: italic;
  font-named-instance: 'Bold Italic';
  src: url('./fonts/Montserrat-BoldItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 800;
  font-display: swap;
  font-style: normal;
  font-named-instance: 'ExtraBold';
  src: url('./fonts/Montserrat-ExtraBold.woff2') format('woff2');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 800;
  font-display: swap;
  font-style: italic;
  font-named-instance: 'ExtraBold Italic';
  src: url('./fonts/Montserrat-ExtraBoldItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 900;
  font-display: swap;
  font-style: normal;
  font-named-instance: 'Black';
  src: url('./fonts/Montserrat-Black.woff2') format('woff2');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 900;
  font-display: swap;
  font-style: italic;
  font-named-instance: 'Black Italic';
  src: url('./fonts/Montserrat-BlackItalic.woff2') format('woff2');
}
