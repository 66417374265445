.page-wrapper {
  height: 100%;
  width: 100%;
  background-color: var(--white-bg);
}

.header-container {
  height: 3vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2vh;

  .breadcrumb-container {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;

    .arrow {
      opacity: 0.7;
      height: 25px;
    }
  }

  .buttons-container {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 1vw;
  }
}

.content-container {
  position: relative;
  height: 91vh;
  width: calc(98vw - var(--navbarWidth));
  // width: 84vw;

  overflow: hidden;
  display: flex;
  flex-direction: row;
  white-space: nowrap;

  .testt{
    width: var(--navbarWidth);
    height: var(--navbarWidth);
    background-color: red;
    position: absolute;
    top :0;
    z-index: 10;
  }

  .section-container {
    width: calc(98vw - var(--navbarWidth));
    min-width: calc(98vw - var(--navbarWidth));
    // width: 84vw;
    // min-width: 84vw;
    
    background-color: white;
    border-radius: 5px;
    overflow-y: scroll;

    .detail-container {
      height: 100%;
      width: 50%;
      padding: 1vw;
    }
  }
}

.detail-page-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;

  section {
    margin-bottom: 30px;
  }
}

.dialog-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
