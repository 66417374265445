.login-wrapper {
  position: relative;
  height: 100vh;
  width: 100vw;
  background-color: #333333;

  .image {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    object-fit: contain;
  }
}

.login-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;

  .header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    opacity: 0.7;

    img {
      height: 35px;
      width: 35px;
      margin-right: 15px;
    }

    span {
      font-size: 20px;
      font-weight: 500;
      color: white;
    }
  }

  .main-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 5vw;
    margin-bottom: 50px;
    height: auto;
    width: 400px;

    .form-header {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;

      .title {
        color: white;
        font-size: 46px;
        font-weight: 600;
        margin-bottom: 15px;
      }

      .subtitle {
        color: white;
        font-size: 24px;
        font-weight: 400;
      }
    }

    .form-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;

      .item-container {
        margin-bottom: 15px;
        width: 100%;
        display: flex;
        flex-direction: column;

        label {
          font-weight: 500;
          margin-bottom: 10px;
          color: #ffffffaa;
        }

        .password-label-container {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          span {
            font-size: 14px;
            color: #ffcb47;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .btn {
      width: 100%;
    }
  }

  .footer-container {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 10px;

    span {
      color: #ffffff80;
      font-size: 14px;

      a {
        margin-left: 2px;
        color: #ffcb47;
        text-decoration: none;

        &:hover {
          color: #df554d;
        }
      }
    }
  }
}
