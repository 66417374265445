h1 {
  @apply font-semibold text-white text-5xl;
}

h2 {
  @apply font-medium text-gray-700 text-2xl;
}

h3 {
  @apply font-semibold text-gray-600 text-xl;
}

h4 {
  @apply font-semibold text-gray-500 text-sm uppercase;
}

.main-content-wrapper {
  height: calc(100vh - 64px);
}

.main-content-container {
  @apply flex flex-col gap-y-8 pl-6 pr-5 pt-4 pb-20;
}

.data-section-row {
  @apply flex flex-row gap-5 flex-wrap;
}

.form-wrapper {
  @apply flex flex-col gap-y-5 pb-20 overflow-y-scroll;
}

.manage-footer {
  @apply flex flex-col gap-y-3 pt-3;
}

.empty-section-message {
  @apply italic text-gray-400 py-3 text-sm;
}

/* Primereact Datatable */
.p-datatable {
  font-size: 14px !important;
  @apply overflow-hidden text-ellipsis whitespace-nowrap;

  // Header
  .p-datatable-thead > tr > th {
    @apply bg-slate-100;

    .p-inputtext {
      padding: 0.3rem 0.4rem;
      @apply text-sm font-semibold;
    }
  }

  .p-paginator {
    @apply bg-slate-100;
  }

  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background-color: transparent;
  }

  .p-button {
    padding: 0.1rem !important;
    height: 30px !important;
    width: 30px !important;
  }

  .p-tag {
    padding: 0.2rem 0.5rem;
  }

  // Datatable
  .p-column-filter-clear-button {
    width: 0 !important;
  }
}

// Rich Text Editor
.ql-editor {
  font-size: 16px;
  font-family:
    Montserrat,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif !important;
}

.ql-toolbar {
  @apply rounded-tl-lg rounded-tr-lg;
}

.ql-container {
  @apply rounded-bl-lg rounded-br-lg;
}

// Buttons
.p-button.p-button-sm {
  padding: 0.4rem 0.8rem !important;
}

.p-button-label {
  font-weight: 600 !important;
}

// Dropdown
.active-custom-dropdown {
  @apply border-custom-blue;

  .p-dropdown-trigger {
    @apply text-slate-400 transition-colors;

    &:hover {
      @apply text-custom-blue;
    }
  }

  .p-dropdown-clear-icon {
    @apply text-slate-400 transition-colors;

    &:hover {
      @apply text-custom-blue;
    }
  }
}
