.app-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex: row;
}

.unauthorized-page {
  height: 100vh;
  width: 100%;
  padding: 0 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #212121;
  font-weight: 500;
  text-align: center;
}

.navbar-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

/* TOP NAVBAR STYLING */
.navbar-scopes-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.navbar-scope-container {
  padding: 0 16px;
  border-radius: 5px;
  color: white;
  font-size: 20px;
  font-weight: 600;
  margin-right: 40px;
}

/* LEFT NAVBAR STYLING */

.navbar-vertical-container {
  height: 100%;
  transition: max-width ease-in-out 0.3s;
  @apply bg-gray-900;
}

.navbar-menu-container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
}

.navbar-handler {
  position: absolute;
  right: -6px;
  background-color: red;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px;
  padding: 2px 2px;
  background-color: var(--dark);
  border: 2px var(--darkGrey) solid;
  z-index: 9;
  cursor: pointer;
  transition: border ease-in-out 0.2s;

  &:hover {
    border: 2px var(--grey) solid;

    .navbar-handler-img {
      opacity: 1;
    }
  }
}

.navbar-handler-img {
  height: 22px;
  width: 16px;
  opacity: 0.6;
  transition: opacity ease-in-out 0.2s;
}

.navbar-submenu-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 0 15px;
}

.navbar-submenu-label {
  color: white;
  opacity: 0.7;
  font-size: small;
  font-weight: 500;
  margin-bottom: 4px;
  height: 15px;
  text-wrap: nowrap;
}

.navbar-submenu-separator {
  height: 1px;
  background-color: white;
  width: 24px;
  opacity: 0.7;
  margin-bottom: 19px;
}

.nav-icon-container {
  height: auto;
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 8px 0;
  transition: opacity 300ms;
}

.nav-icon-img {
  height: 24px;
  width: 24px;
  object-fit: contain;
}

.nav-icon-text {
  margin-left: 14px;
  font-size: 16px;
  font-weight: 500;
  color: white;
  white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  margin-right: 25px;
}

.nav-icon-button-active {
  opacity: 1;
}

.nav-icon-button-inactive {
  opacity: 0.55;
}

.bottom-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-bottom: 15px;
  gap: 5px;

  .version {
    font-size: 14px;
    color: white;
    cursor: pointer;
    opacity: 0.7;
    &:hover {
      opacity: 1;
      text-decoration: underline;
    }
  }
}
