/* Key Indicator Buttons */

.key-indicator-button-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 12vw;
  height: 17vh;
  padding: 1.25vh;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1.5px 4px;
  border-radius: 5px;
  cursor: pointer;
  transition: box-shadow 300ms ease-out;
  outline-style: solid;
  background-color: #fff;
}

.key-indicator-button-container:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

/* Key indicator scrollview */
.scrollview-right-controler-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 6vw;
  height: 100%;
  padding-right: 0.5vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  background: linear-gradient(
    to right,
    transparent,
    #edf0f4bb 70%,
    #edf0f4dd 30%,
    #edf0f4
  );
}

.scrollview-left-controler-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 6vw;
  height: 100%;
  padding-left: 0.5vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  background: linear-gradient(
    to left,
    transparent,
    #edf0f4bb 70%,
    #edf0f4dd 30%,
    #edf0f4
  );
}

.scrollview-arrow {
  background-color: #fff;
  border-radius: 1.5vw;
  height: 3vw;
  width: 3vw;
  padding: 0.75vw;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1.5px 4px;
  transition: all 0.3s ease-out;
  cursor: pointer;
}

.scrollview-arrow:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
