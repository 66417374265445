@use 'sass:math';

// core
.p-chips {
  display: inline-flex;
}

.p-chips-multiple-container {
  margin: 0;
  padding: 0;
  list-style-type: none;
  cursor: text;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.p-chips-token {
  cursor: default;
  display: inline-flex;
  align-items: center;
  flex: 0 0 auto;
}

.p-chips-input-token {
  flex: 1 1 auto;
  display: inline-flex;
}

.p-chips-token-icon {
  cursor: pointer;
}

.p-chips-input-token input {
  border: 0 none;
  outline: 0 none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  box-shadow: none;
  border-radius: 0;
  width: 100%;
}

.p-fluid .p-chips {
  display: flex;
}

// theme
.p-chips {
  &:not(.p-disabled):hover {
    .p-chips-multiple-container {
      border-color: $inputHoverBorderColor;
    }
  }

  &:not(.p-disabled).p-focus {
    .p-chips-multiple-container {
      @include focused-input();
    }
  }

  .p-chips-multiple-container {
    padding: math.div(nth($inputPadding, 1), 2) nth($inputPadding, 2);
    outline-color: transparent;

    .p-chips-token {
      padding: math.div(nth($inputPadding, 1), 2) nth($inputPadding, 2);
      margin-right: $inlineSpacing;
      background: $chipBg;
      color: $chipTextColor;
      border-radius: $chipBorderRadius;

      &.p-focus {
        background: $chipFocusBg;
        color: $chipFocusTextColor;
      }

      .p-chips-token-icon {
        margin-left: $inlineSpacing;
      }
    }

    .p-chips-input-token {
      padding: math.div(nth($inputPadding, 1), 2) 0;

      input {
        font-family: var(--font-family);
        font-feature-settings: var(--font-feature-settings, normal);
        font-size: $inputTextFontSize;
        color: $textColor;
        padding: 0;
        margin: 0;
      }
    }
  }

  &.p-invalid.p-component > .p-inputtext {
    @include invalid-input();
  }
}
