.app-name {
  position: relative;
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  flex-direction: row;
  align-items: center;
  // justify-content: space-between;
  padding: 12px;
  height: 70px;

  .logo {
    height: 30px;
    width: 30px;
    object-fit: contain;
  }

  .name {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    white-space: pre-line;
  }
}
