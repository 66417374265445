.full-page-datatable {
  overflow-y: hidden !important;
  .p-datatable {
    > .p-datatable-wrapper {
      height: calc(91vh - 125px);
    }

    .p-column-filter {
      .p-inputtext {
        padding: 0.3rem;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .p-datatable-thead > tr > th {
      padding: 0.7rem 0.9rem !important;
    }

    .edit-icon {
      color: var(--grey);
      transition: all 300ms;

      &:hover {
        color: var(--blue);
      }
    }
  }
}

.font-bold {
  font-weight: 600;
}

.table td,
table th {
  text-align: center;
}

.p-datatable {
  font-size: 14px !important;
}
